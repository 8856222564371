import BasePage from '@/found/components/table_page';

export default {
  name: 'budget_input_list',
  extends: BasePage,
  data() {
    return {

    };
  },
  components: {

  },
  async created() {
    await this.getConfigList('budget_input_list');
  },
  methods: {},
};
